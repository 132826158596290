
(function ( $ , undefined  ) {


		var imresize_methods = {

			init : function ( options ) {
			
				var options = $.extend( {
					
				
					outer : '',
	
					outer_width : false,

					outer_height : false,

					method : 'notfield',  

                    max_relation : false, 
 
                    show_min_img : false,

					k : 1,

					noresize_to_center : false, 
					callback : ""
					
				}, options );
			
				return this.each( function () {
				
					var $this = $(this);
					var data = $this.data("imResize");
					
					if ( !data ) {

						var forImgDelay = function () {
							
							if (!imresize_methods.isImageLoaded.call( $this , $this[0] )) {
								var delay = setTimeout(forImgDelay , 100);
								return false;
							}


							var inner = $this;   

							var outer = ( options.outer.length > 0 ) ? $this.parents( options.outer ) : $this.parent();
												

							var inner_width = !!inner[0].naturalWidth ? inner[0].naturalWidth : inner.width();

							var inner_height = !!inner[0].naturalHeight ? inner[0].naturalHeight : inner.height();

							var inner_rel = inner_width/inner_height;

							var outer_width = options.outer_width || outer.width();

							var outer_height = options.outer_height || outer.height();
													
							$this.data( "imResize", {
								
								inner : inner,                                        
								outer : outer,                                        

								inner_width : inner_width,                                   
								inner_height : inner_height,                                  
								inner_rel : inner_rel,                                        
						
								outer_width : outer_width,                                    
								outer_height : outer_height,                                 
								
								method : options.method,                                      
                                max_relation : options.max_relation,                          
                                show_min_img : options.show_min_img,                          
								k : options.k,                                                
								noresize_to_center : options.noresize_to_center,              
								callback : options.callback                                  
								
							});
												
							imresize_methods.move.call( $this , $(this) );

							
							$(window).resize(function () {
								imresize_methods.resize.call( $this , options.outer_width , options.outer_height );
							});
						
						}
						forImgDelay();

					} 				
				})
			},
			resize : function ( w , h ) {
				return this.each(function () {
					var $this = $(this);
					var data = $this.data("imResize");
                    
                    if (typeof data == "undefined") return false;
									
					data.outer_width = w || data.outer.width();
					data.outer_height = h || data.outer.height();
					
					imresize_methods.move.call( $this );
					

				});
			},
			move : function () {
				
				return this.each(function () {
					var $this = $(this);
					var data = $this.data("imResize");
					
					var new_inner_width;
					var new_inner_height;
					var new_inner_top;
					var new_inner_left;
					
					var relation = data.outer_width*data.inner_height < data.inner_width*data.outer_height; 
                    if ( data.max_relation && data.inner_width / data.inner_height > data.max_relation ) data.method = 'field'    
					var resize_type = data.noresize_to_center;                          
					

					function calc_default () {
						if ( relation ) {
							new_inner_height = data.outer_height*data.k;
							new_inner_width = data.outer_height*data.inner_rel*data.k;
							new_inner_top = (data.outer_height - new_inner_height)/2;
							new_inner_left = (data.outer_width - new_inner_width)/2;
						}
						else {
							new_inner_width = data.outer_width*data.k;
							new_inner_height = data.outer_width/data.inner_rel*data.k;
							new_inner_top = (data.outer_height - new_inner_height)/2;
							new_inner_left = (data.outer_width - new_inner_width)/2;
						}
					}

					function calc_noresize ( option ) {
						new_inner_width = data.inner_width*data.k;
						new_inner_height = data.inner_height*data.k;
						
						switch ( option ) { 
							case true:
								new_inner_top = (data.outer_height - new_inner_height)/2;
								new_inner_left = (data.outer_width - new_inner_width)/2;
								break;
							case 'top':
								new_inner_top = (data.outer_height - new_inner_height)/2;
								break;
							case 'left':
								new_inner_left = (data.outer_width - new_inner_width)/2;
								break;
						}		
					}
					
					if ( data.method == 'field' ) {
						relation = !relation;
					} 
                    
                    if ( data.show_min_img == true ) {
                        if (data.outer_width/data.inner_width > 1.5 && data.outer_height/data.inner_height > 1.5) data.k = data.inner_width/data.outer_width;
                    }

					if ( !!data.noresize_to_center ) calc_noresize(data.noresize_to_center);
					else calc_default();
					
					data.inner.css({
						width : new_inner_width,
						height : new_inner_height,
						left : new_inner_left,
						top : new_inner_top
					})

					if( data.callback && typeof data.callback == "function" ) {
						data.callback.call( this, data.indicators.eq( tab_index ) );
					}
				});
			},

			isImageLoaded : function ( img ) {

				if (!img.complete) {
					return false;
				}

				if (typeof img.naturalWidth !== "undefined" && img.naturalWidth === 0) {
					return false;
				}
				return true;
			},
			destructor : function () {
				return this.each( function () {
					var $this = $(this);
					var data = $this.removeData("imResize");
					$this.off('.imResize');
                    $this.removeAttr('style');
				});
			}
		}
	$.fn.imResize = function ( method ) {
		if ( imresize_methods[method] ) {
			return imresize_methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
		}
		else if ( typeof method === 'object' || !method ) {
			return imresize_methods.init.apply(this, arguments);
		}
		else {
			$.error('Method ' +  method + ' does not exist on jQuery.accordIon' );
		}  		
	}
	

})(jQuery);