(function ( $ , undefined  ) {

		
		var accordion_methods = {
			
			init : function ( options ) {
			
				var options = $.extend( {
		
					
					tab_second_click_close : true,
					
					closed_tabs_after_open_new : true,
					
					open_first_or_active : false,
					
					blocked_top_links_go : false,
					
					tabs_class : '> ul > li:has(ul) > a', //.link
					
					blocks_class : '> ul > li > ul', //.container
					
					indicators_class : '> ul > li:has(ul) > a', //.turn
					
					active_tabs_class : '.active, [data-active="true"]',
					
					animate_method : 'slide',
					
					animate_speed : 150,
					
					handler_type : 'click',

					update : false,
					
					callback : ""
					
				}, options );
			
				return this.each( function () {
				
					var $this = $(this);
					var data = $this.data("accordIon");
					
					if ( !data ) { 
					
						
						var tabs = $this.find( options.tabs_class );
						
						var blocks = $this.find( options.blocks_class );
						
						var indicators = $this.find( options.indicators_class );
						
						var active_tabs = $this.find( options.active_tabs_class );

						$this.addClass('accordIon');
						
						$this.data( "accordIon", {
						
							container : $this,                                        
							tab_second_click_close : options.tab_second_click_close,    
							closed_tabs_after_open_new : options.closed_tabs_after_open_new, 
							open_first_or_active : options.open_first_or_active,      
							blocked_top_links_go : options.blocked_top_links_go,     
							tabs : tabs,                                          
							blocks : blocks,                                         
							indicators : indicators,                                  
							active_tabs : active_tabs,  
                            active_tabs_class : options.active_tabs_class,                   
							animate_method : options.animate_method,                           
							animate_speed : options.animate_speed,                            
							handler_type : options.handler_type,                        
							callback : options.callback                            
							
						});
						
						
						tabs.css( 'cursor', 'pointer' );
						
						if ( options.handler_type == 'click' ) {
						
						$this.on('click.accordIon', options.tabs_class, function ( event ) {
							if ( options.blocked_top_links_go ) {
								var href = $(event.currentTarget).attr('href');
								
								
								if ($(event.currentTarget).parent().find('ul').length) {
									event.preventDefault();
								}
							}
							var elem_index = $this.data("accordIon").tabs.index(event.currentTarget);
							accordion_methods.move.call( $this, elem_index );
						});
						}
						else if ( options.handler_type == 'hover' ) {
						
						$this.on('hover.accordIon', options.tabs_class, function ( event ) {
							if ( options.blocked_top_links_go ) {
								var href = $(event.currentTarget).attr('href');
								
								
								if ($(event.currentTarget).parent().find('ul').length) {
									event.preventDefault();
								}
							}
							var elem_index = $this.data("accordIon").tabs.index(event.currentTarget);
							accordion_methods.move.call( $this, elem_index );
						});
						}
						
						accordion_methods.start.call( $this );

					} 
					else if (options.update) {
						var tabs = $this.find( options.tabs_class );
						
						var blocks = $this.find( options.blocks_class );
						
						var indicators = $this.find( options.indicators_class );
						
						var active_tabs = $this.find( options.active_tabs_class );
						
						$this.data( "accordIon", Object.assign({}, $this.data("accordIon"), {    
							tabs : tabs,                                          
							blocks : blocks,                                         
							indicators : indicators,                                  
							active_tabs : active_tabs
						}));

						accordion_methods.start.call( $this );
					}
					
				})
			},
			
			start : function () {
				return this.each(function () {
					var $this = $(this);
					var data = $this.data("accordIon");
					
					data.blocks.accordIon_slide( {turn : 'off', method : data.animate_method}, 0 );
					data.indicators.removeClass('accordion_minus').addClass('accordion_plus');
				
					switch ( data.open_first_or_active ) {
						case 'first': 
							accordion_methods.move.call( $this, 0 );
							break;
						case 'active':
							[].forEach.call(data.tabs, function (item, i) {
								if ($(item).filter(data.active_tabs_class).length) {
									accordion_methods.move.call( $this, i );
								}
							});
							break;
						default: 
							break;
					}
				});
			},
			move : function ( elem_index ) {
				
				return this.each(function () {
					var $this = $(this);
					var data = $this.data("accordIon");
					
					var elem = $( data.tabs ).eq( elem_index ) ;  
					var tab_index = elem_index;                
					
					
					var tab_open = elem.hasClass('accordion_minus');   
					if ( !tab_open ) {
						tab_open = elem.parents('.accordion_minus').hasClass('accordion_minus')
					}
					if ( !tab_open ) {
						tab_open = elem.find('.accordion_minus').hasClass('accordion_minus');
					}
					
					
					if ( tab_open && !data.tab_second_click_close ) {
						return false;
					}
					
					else if ( tab_open ) {
						data.indicators.eq( tab_index ).removeClass('accordion_minus').addClass('accordion_plus');
						data.blocks.eq( tab_index ).accordIon_slide( {turn : 'off', method : data.animate_method}, data.animate_speed )
                        //data.tabs.eq( tab_index ).removeClass(data.active_tabs_class);
					}
					
					else {
						
						if ( data.closed_tabs_after_open_new ) {
							data.indicators.removeClass('accordion_minus').addClass('accordion_plus').eq( tab_index ).removeClass('accordion_plus').addClass('accordion_minus');
							data.blocks.not(':eq('+tab_index+')').accordIon_slide( {turn : 'off', method : data.animate_method, all: true}, data.animate_speed )
                            data.blocks.eq( tab_index ).accordIon_slide( {turn : 'on', method : data.animate_method}, data.animate_speed );
                            //data.tabs.removeClass(data.active_tabs_class).eq( tab_index ).addClass(data.active_tabs_class);
						}
						else {
							data.indicators.eq( tab_index ).removeClass('accordion_plus').addClass('accordion_minus');
							data.blocks.eq( tab_index ).accordIon_slide( {turn : 'on', method : data.animate_method}, data.animate_speed );
                            //data.tabs.eq( tab_index ).addClass(data.active_tabs_class);
						}
						
					}
					
					
					if( data.callback && typeof data.callback == "function" ) {
						data.callback.call( this, data.indicators.eq( tab_index ) );
					}
				});
			},
			closeAll : function () {
				return this.each( function () {
					var $this = $(this);
					var data = $this.data("accordIon");
					
					data.indicators.removeClass('accordion_minus').addClass('accordion_plus');
					data.blocks.accordIon_slide( {turn : 'off', method : data.animate_method, all: true }, data.animate_speed );
					
				});
			},
			destructor : function () {
				return this.each( function () {
					var $this = $(this);
					var data = $this.removeData("accordIon");
					$this.off('.accordIon');
				});
			}
		}
	
	$.fn.accordIon = function ( method ) {
		if ( accordion_methods[method] ) {
			return accordion_methods[method].apply( this, Array.prototype.slice.call( arguments, 1 ));
		}
		else if ( typeof method === 'object' || !method ) {
			return accordion_methods.init.apply(this, arguments);
		}
		else {
			$.error('Method ' +  method + ' does not exist on jQuery.accordIon' );
		}  		
	}
	
	$.fn.accordIon_slide = function ( options, speed ) {
	
		var options = $.extend({
				method : 'slide',
				turn : 'on',
                all : false
				
			}, options);
	
		return this.each(function () {
		
			var $this = $(this);
			
			var slide_methods = {
				slide : {
					on : function () {
						$this.stop().slideDown( speed );
					},
					off : function () {
						$this.stop().slideUp( speed );
					}
				},
				change_height : {
					on : function () {
						var into_height = 0;
						$this.children().each(function () {
							into_height = into_height + this.clientHeight;
						})
						$this.animate({'height': into_height});
					},
					off : function (all) {
                        if (all && $this.hasClass('accordion_plus')) $this.css({height : $this.css('min-height')});
						else $this.animate({height : $this.css('min-height')},200);
					}
				},
				comments_slide : {
					on : function () {
						var min_height = parseInt( $this.css('min-height') );
						
						$this.find('> div').css('position' , 'relative');
					
						var into_height = 0;
						$this.children().each(function () {
							into_height = into_height + $(this).height();
						})
						$this.animate({ 'height' : into_height }, 300 , function () {
							$this.css({'height': 'auto'});
							//$this.find('div').css({ 'position' : 'relative'});
						})
						.find('div').animate({'bottom' : 0 }, 300);
					},
					off : function () {
						var min_height = parseInt( $this.css('min-height') );
					
						var into_height = 0;
						$this.children().each(function () {
							into_height = into_height + $(this).height();
						})
						var min_height = ( into_height != 0 ) ? parseInt( $this.css('min-height') ) : 0;
						
						if ( min_height == 0 ) {
							$this.find('> div').css('position' , 'absolute');
						}
						
						$this.animate({ height : $this.css('min-height') } , 200 , function () {
							//$this.find('div').css({ 'position' : 'absolute'});
						})
						.find('> div').animate({'bottom' : into_height - min_height }, 200 , function () {
							$(this).css({'position' : 'absolute' , 'bottom' : 0})
						});
					}
				}
			}
			
			switch ( options.method ) {
				case 'slide':
					if ( options.turn == 'on' ) {
						slide_methods.slide.on();
					}
					else if (  options.turn == 'off'  ) {
						slide_methods.slide.off();
					}
					break;
				case 'change_height':
					if ( options.turn == 'on' ) {
						slide_methods.change_height.on();
					}
					else if (  options.turn == 'off'  ) {
						slide_methods.change_height.off(all = options.all);
					}
					break;
				case 'comments_slide':
					if ( options.turn == 'on' ) {
						slide_methods.comments_slide.on();
					}
					else if (  options.turn == 'off'  ) {
						slide_methods.comments_slide.off();
					}
					break;
				default:
					break;
			}
			
			return this;
		})
		
	}

})(jQuery);